// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import './src/jquery'

import DatePicker from './src/datepicker'
import S3Uploader from './src/s3-uploader'
import AudioHandler from './src/audio-handler'
import TusUploader from "./src/tus-uploader"

document.addEventListener('turbo:load', () => {
  new DatePicker();
  new S3Uploader();
  new AudioHandler();
  new TusUploader();
  $('[data-href]').on('click', function(e) {
    if (e.target.tagName.toLowerCase() == 'a') return;
    e.stopPropagation();
    Turbo.visit($(this).data('href'));
  });
});

// turbolinks fix for jQuery datepicker (https://github.com/turbolinks/turbolinks/issues/253#issuecomment-289101048)
document.addEventListener('turbo:before-cache', () => {
  $.datepicker.dpDiv.remove();
  $('.datepicker').datepicker('destroy');
});
document.addEventListener('turbo:before-render', (event) => $.datepicker.dpDiv.appendTo(event.detail.newBody));
