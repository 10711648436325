import { S3Client } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'

class S3Uploader {

  constructor() {
    this.$form = $('._s3_uploader');
    if (this.$form.length < 1) return;
    this.$form.on('submit', () => this.validate());
    this.$mfile = $('._up_mfile'); this.$mfile_flag = $('._up_mfile_flag');
  }

  uploadMedia() {
    let bucket = $('._s3_bucket').val(), key = $('._s3_key').val();
    let upload = new Upload({
      client: new S3Client({
        region: 'us-1',
        credentials: {
          accessKeyId: this.$form.data('s3-user'),
          secretAccessKey: this.$form.data('s3-pass')
        },
        endpoint: this.$form.data('s3-endp')
      }),
      params: { Bucket: bucket, Key: key, ContentType: '', Body: this.getFile(this.$mfile) },
      partSize: 5 * 1024 * 1024,
      leavePartsOnError: false
    });
    upload.on("httpUploadProgress", (progress) => {
      let percentage = `${(progress.loaded * 100 / progress.total).toFixed()}%`;
      $('._progress .progress-inner').css('width', percentage).text(percentage);
    });
    $('._progress').show();
    upload.done()
    .then(() => {
      // we don't want Rails to read the file, so remember the upload, but clear the file
      if (this.hasFile(this.$mfile)) this.$mfile_flag.val('uploaded');
      this.$mfile.val('');
      this.$form.off('submit');
      this.$form.trigger('submit');
    })
    .catch(error => {
      $('._error-description').text(error);
      $('._progress').hide(); $('._error').show();
    });
  }

  hasFile(fileField) {
    return fileField.length > 0 && fileField[0].files.length > 0;
  }

  getFile(fileField) {
    return fileField[0].files[0];
  }

  uploadMediaAsync() {
    if (!this.hasFile(this.$mfile)) {
      this.$form.off('submit');
      this.$form.trigger('submit');
    } else {
      this.uploadMedia();
    };
  }

  validate() {
    if ($('._audio_key').length > 0) {
      let date = $('._audio_key').val();
      if (!date.match(/^\d{4}-\d{2}-\d{2}$/)) {
        alert(`"${date}" is not a valid date in YYYY-MM-DD format.`);
        return false;
      }
      $.ajax({
        url: `/radio_programs/check-date/${$('#episode_id').val() || 0}/${$('#episode_code').val()}`,
        dataType: 'json',
        success: (data) => {
          if ('OK' == data)
            this.uploadMediaAsync();
          else
            alert('An episode with this date already exists.  Please check the date and try again.');
        }
      });
      return false;
    }
    if (!this.hasFile(this.$mfile)) return true;
    this.uploadMedia();
    return false;
  }
}

export default S3Uploader;

