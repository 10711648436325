class AudioHandler {

  pad0(n) {
    return n > 9 ? n : `0${n}`;
  }

  updateKey(date) {
    let key = date.match(/^\d{4}-\d{2}-\d{2}$/) ? date.replace(/-/g, '') : '00000000';
    if (this.$form.data('ts')) {
      this.$key.val(this.$key.val().replace(/\d{8}(.{6})\.mp3$/, `${key}$1.mp3`));
      this.$code.val(this.$code.val().replace(/\d{8}(.{6})$/, `${key}$1`));
    } else {
      this.$key.val(this.$key.val().replace(/\d{8}\.mp3$/, `${key}.mp3`));
      this.$code.val(this.$code.val().replace(/\d{8}$/, key));
    }
  }

  constructor() {
    this.$form = $('#_audio_form');
    if (this.$form.length < 1) return;
    this.$date = $('._audio_key');
    this.$fileField = $('._up_afile');
    this.$key = $('#episode_key');
    this.$code = $('#episode_code');

    // audio pseudo-SIMS code and S3 key depend on production date value
    this.$date.on('change', () => {
      this.updateKey(this.$date.val());
    });

    // news flashes auto-populate audio name and production date from file name, if name not already entered
    this.$fileField.on('change', () => {
      let $name = $('#episode_name');
      if ($name.val() != '') return;
      let file = this.$fileField[0].files[0];
      $('#episode_name').val(file.name.normalize().replace(/\.mp3$/, ''));
      if (this.$form.data('ts')) {
        let date = new Date(file.lastModified),
            fdate = `${date.getFullYear()}-${this.pad0(date.getMonth() + 1)}-${this.pad0(date.getDate())}`;
        $('#episode_production_date').val(fdate);
        this.updateKey(fdate);
      }
    });
  }
}

export default AudioHandler;
